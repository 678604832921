import Ha3Ho3 from './svg/hahahahohoho_anim_w_bg.svg';
import Ha3Ho3DT from './svg/hahahahohoho_anim_desktop.svg';

const SectionOne = () => {
  //console.log("title section loaded");
  return (
    <div className='title_section'>
       <object id="mobile" type="image/svg+xml" alt="holiday design background" data={Ha3Ho3}>svg-animation</object>
       <object id="desktop" type="image/svg+xml" alt="holiday design background" data={Ha3Ho3DT}>svg-animation</object>
    </div>
  )};

export default SectionOne;
