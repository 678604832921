import React, { lazy, Suspense  } from 'react';
import "./styles.css";

//import Loadable from 'react-loadable';
//import Loading from './Loading';

import TitleSection from "./TitleSection";
//import IntroSection from "./IntroSection";
//import DetailsSection from "./DetailsSection";
//import GigglrSection from "./GigglrSection";
//import VideoSection from "./VideoSection";
//import LinksSection from "./LinksSection";
//import ThanksSection from "./ThanksSection";
//import DonationSection from "./DonationSection";

//const TitleSection = lazy(() => import("./TitleSection"));
const IntroSection = lazy(() => import("./IntroSection"));
const DetailsSection = lazy(() => import("./DetailsSection"));
const GigglrSection = lazy(() => import("./GigglrSection"));
const VideoSection = lazy(() => import("./VideoSection"));
const ThanksSection = lazy(() => import("./ThanksSection"));
const LinksSection = lazy(() => import("./LinksSection"));
const DonationSection = lazy(() => import("./DonationSection"));

// const TitleSection = Loadable({
//   loader: () => import('./TitleSection'),
//   loading: Loading,
// });

export default class App extends React.Component {
  render() {
    return (
      <>
           <TitleSection />

    <Suspense fallback={<h1>Still Loading…</h1>}>

     <GigglrSection />
     <VideoSection />
     <IntroSection />
     <DetailsSection />
     <LinksSection />
     <DonationSection />
     <ThanksSection />
    </Suspense>
      </>
    )
  }
}
